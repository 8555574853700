// TODO CHECK WHERE IT IS USED AND UPDATE

const LeadSource = {
    TRUSTUP: 'trustup',
    WEBSITE: 'website',
    FACEBOOK: 'facebook',
    OTHER: 'other',
};

export default LeadSource;
