import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type LeadRelationships = {
    user: ToOneRelationship<ModelType.USERS>;
    contact: ToOneRelationship<ModelType.CONTACTS>;
    invoice: ToManyRelationship<ModelType.INVOICES>;
    calendarEvents: ToManyRelationship<ModelType.CALENDAR_EVENTS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    source: ToOneRelationship<ModelType.SOURCES>;
};

type LeadAttributes = {
    title: string;
    status: string;
    notes: string;
    addedAt: string;
    archivedAt: string;
    address: Address | null;
};

type LeadMeta = {
    totalQuoted: number;
};

export default class LeadSchema extends BaseSchema<LeadAttributes, LeadMeta, LeadRelationships> {
    protected type: ModelType = ModelType.LEADS;
}
